import { useMemo } from 'react';
import { useAuthProvider, useNotify, useRecordContext } from 'react-admin';

import { useQuery } from 'react-query';

import MailLockIcon from '@mui/icons-material/MailLock';
import { Button, CircularProgress, Tooltip } from '@mui/material';

import {
  HasOnError,
  HasOnSuccess
} from 'shared/components/interfaces/MutationComponentProps';
import { ResourceName } from 'shared/enums';

import { getApiRoot } from 'shared/utils/helperFunctions/dotenv.utils';
import { buildBlazepodHeaders } from 'shared/utils/helperFunctions/fetchHelper';

import { AppUser } from '../models/AppUser.model';

export function ResetPasswordButton(props: Partial<HasOnError & HasOnSuccess>) {
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const record = useRecordContext<AppUser>();
  const fetchResetPassword = async () => {
    const res = await fetch(
      `${getApiRoot()}/${ResourceName.USERS}/reset_password?email=${encodeURIComponent(
        record.email
      )}`,
      await buildBlazepodHeaders(authProvider)
    );
    return res.json();
  };
  const handleMutateSuccess = useMemo(
    () => () => {
      notify(`Email sent.`, { type: 'success' });
      props.onSuccess != null && props.onSuccess();
    },
    [notify, props]
  );
  const handleMutateFailure = useMemo(
    () => (e?: Error) => {
      notify(e?.message ?? 'Something went wrong', { type: 'error' });
      props.onError != null && props.onError();
    },
    [notify, props]
  );
  const { status, refetch } = useQuery('reset_password', fetchResetPassword, {
    onSuccess: handleMutateSuccess,
    onError: handleMutateFailure,
    enabled: false
  });

  const buttonLabel = useMemo(() => {
    if (record == null) {
      return 'Loading User...';
    }
    return 'Reset Password';
  }, [record]);

  const handleClick = () => {
    refetch();
  };

  const isDisabled = useMemo(
    () => record == null || status === 'loading',
    [record, status]
  );

  if (record == null) {
    return <CircularProgress size='sm' />;
  }
  return (
    <Tooltip
      arrow
      placement='left'
      title={'Send reset password email'}
      describeChild
      enterDelay={1000}
    >
      <div>
        <Button
          onClick={handleClick}
          disabled={isDisabled}
          size='small'
          variant='contained'
        >
          <MailLockIcon />
          {buttonLabel}
        </Button>
      </div>
    </Tooltip>
  );
}
